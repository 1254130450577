/** Default Styling **/


/*****Fonts*****/

@font-face {
  font-family: 'Poppins-Regular';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'DoubleTake';
  src: url('assets/fonts/000DoubleTake-Regular.ttf') format('truetype');
}

/***** Heading Styles *****/
@font-face {
  font-family: 'GritSans-Bold';
  src: url('assets/fonts/gritsans-bold-webfont.woff2') format('woff2'),
    url('assets/fonts/gritsans-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Sunborn-Sansone';
  src: url('assets/fonts/sunborn-sansone-webfont.woff2') format('woff2'),
    url('assets/fonts/sunborn-sansone-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}



@font-face {
  font-family: 'Librefranklin-Light';
  src: url('assets/fonts/LibreFranklin-Light.otf') format('truetype');
  font-weight: 200;
  font-style: normal;

}


@font-face {
  font-family: 'Librefranklin-Regular';
  src: url('assets/fonts/LibreFranklin-Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Librefranklin-SemiBold';
  src: url('assets/fonts/LibreFranklin-SemiBold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Librefranklin-SemiBold';
  src: url('assets/fonts/LibreFranklin-SemiBold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Librefranklin-Bold';
  src: url('assets/fonts/LibreFranklin-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;

}



@font-face {
  font-family: 'Librefranklin-Italic';
  src: url('assets/fonts/LibreFranklin-Italic.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Librefranklin-Black';
  src: url('assets/fonts/LibreFranklin-Black.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.phone-field .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  letter-spacing: -0.7px !important;
}

.grit-sansbold {
  font-family: 'GritSans-Bold' !important;
}

.libre-franklinlight {
  font-family: 'Librefranklin-Light' !important;
}

.libre-franklin {
  font-family: 'Librefranklin-Regular' !important;
}

.sunbornsans-one {
  font-family: 'Sunborn-Sansone' !important;
}

h1 {
  font-family: 'Sunborn-Sansone' !important;
  color: #062C43;
  font-size: 25px !important;
}

h5 {
  font-family: 'Sunborn-Sansone' !important;
  color: #414141;
}

h6 {
  font-family: 'Sunborn-Sansone' !important;
  font-size: 16px !important;
  color: #525252;
}


/***** Labels *****/

.label {
  color: #0075BF;
  font-family: 'GritSans-Bold' !important;
  font-size: 14px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.caption-grey {
  color: #707070;
  text-transform: uppercase;
}


/****** Buttons ******/

.custom-btn,
button {
  font-family: 'Sunborn-Sansone' !important;
  font-size: 15px;
  height: 50px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2) !important;
  text-transform: uppercase;
  border-radius: 0 !important;
  letter-spacing: 2.25px !important;
}

.custom-btn:focus {
  outline: 1px dotted #79C043 !important;
  outline-offset: 2px !important;
}

.custom-btn:disabled {
  background: black;
  color: white;
  opacity: 0.25;
}

.cta1-btn {
  background: #0075BF;
  color: white;
}

.cta1-btn:hover {
  background: #062C43;
  color: white;
}

.cta2-btn {
  background: white;
  color: #0075BF;
}

.cta2-btn:hover {
  color: #79C043;
}

.cta3-btn {
  background: #062C43 !important;
  color: white;
}

.cta3-btn:hover {
  background: #79c043 !important;
  color: white;
}


/***** Toggle Button Style *****/

.selected-btn.MuiToggleButton-standard {
  background: #062C43;
  color: white;
  border: none;
  width: 100%;
  height: 60px;
  margin: 0px 8px 0px 0px !important;
  font-size: 13px;
}

.selected-btn.MuiToggleButton-standard:focus {
  /* outline: 1px dotted #0075EF !important; */
  outline-offset: 2px !important;
}

/* .selected-btn.MuiToggleButton-standard:hover {
  background: #062C43;
} */

.selected-btn.Mui-selected {
  background: #0075BF !important;
  color: white !important;
}

/****** Toast Styling  ******/

.Toastify__toast-theme--light .Toastify__toast-body {
  font-family: 'Librefranklin-Regular' !important;
}

/* .Toastify__toast-body{
  font-family: 'Librefranklin-Regular' !important;
} */

@media only screen and (max-width: 480px) {

  .Toastify__toast-container .Toastify__toast--success {
    display: none !important;
  }
}

.Toastify__toast--error .Toastify__progress-bar--error {
  background-color: #cd2026 !important;
}

.Toastify__toast--error svg {
  fill: #cd2026 !important;
}

.Toastify__toast--success .Toastify__progress-bar--success {
  background-color: #2e8540 !important;
}
.Toastify {
  position: relative;
}
.Toastify__close-button {
  opacity: 1 !important;
}
.Toastify__toast-theme--dark {
overflow: visible !important;
}
.Toastify__toast--success svg {
  fill: #FFFFFF !important;
  position: absolute;
  margin-top: -37px;
  border-radius: 20px;
  height: 16px !important;
  width: 16px !important;
  background-color: #052C42;
  border: 2.5px solid #052C42;
  align-items: center;
  margin-left: -10px;
  padding: 1px 0px 1px 2px;
}


.Toastify__toast-theme--dark {
  font-family: 'Librefranklin-Bold' !important;
  background: url('./assets/imgs/backgroundToast-Success.svg') !important;
  border-radius: 16px !important;
  width: 360px !important;
  color: #052C42 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.Toastify__toast-theme--dark .Toastify__toast-body {
  padding: 6px 32px 6px 42px !important;
  text-align: center;
}


.Toastify__toast-theme--dark .Toastify__toast-body .Toastify__toast-icon .Toastify--animate-icon .Toastify__zoom-enter img {
  margin-left: -13px;
}

/****** Form Elements ******/


.MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled,
.MuiTextField-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused,
.MuiTextField-root .MuiInputLabel-root.Mui-error {
  font-family: 'Sunborn-Sansone' !important;
  padding: 20px 0px 0px 9px;
  color: #062C43;
  font-size: 10px;
}

.MuiTextField-root .MuiInputLabel-root.Mui-focused {
  padding: 0px;
}

.MuiTextField-root .MuiInputLabel-root.Mui-error {
  color: #E61F00;
  padding: 0px;
}

.MuiTextField-root .MuiInputLabel-root.Mui-error.Mui-focused,
.MuiTextField-root .MuiInputLabel-root.Mui-error.MuiFormLabel-filled {
  padding: 20px 0px 0px 9px !important;
  color: #E61F00 !important;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiFormControl-root .MuiInputLabel-root.Mui-focused,
.MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled,
.MuiFormControl-root .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
  font-family: 'Sunborn-Sansone' !important;
  padding: 20px 0px 0px 9px;
  color: #052C42;
  font-size: 10px;
  letter-spacing: 1.7px;
}

.MuiInputBase-root {
  border-radius: 0px !important;
  padding: 0px !important;
}

.MuiTextField-root {
  width: 100%;
}

.MuiFormControl-root input,
.MuiFormControl-root .MuiSelect-select {
  color: #000;
  border-radius: 0;
  font-family: 'Librefranklin-Regular' !important;
  caret-color: black !important;
  padding: 8px 20px !important;
}

fieldset {
  border: none !important;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 6%), 0px 4px 5px 0px rgb(0 0 0 / 6%), 0px 1px 10px 0px rgb(0 0 0 / 6%) !important;
}


/***** Body *****/

* {
  margin: 0;
  padding: 0;
}

.card-border {
  border-radius: 0px !important;
  border: 1px solid blue;
}

.slot-label {
  background: #79C043 !important;
  color: white !important;
  width: calc(100% - 9px) !important;
  display: inline-block !important;
  text-align: center;
  font-size: 12px !important;
  font-family: 'Sunborn-Sansone' !important;
  padding: 8px 0px !important;
  letter-spacing: 1.5px !important;
}

/***** Scrollbar Style *****/
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #7e7e7e;
  border-radius: 20px;
  border: 3px solid #7e7e7e;
}

/* mobile field custom css start */

.mobile-field .MuiInputLabel-root.Mui-focused,
.mobile-field .MuiInputLabel-root.Mui-focused.Mui-error {
  font-family: 'Sunborn-Sansone' !important;
  padding: 20px 0px 0px 9px !important;
  font-size: 10px !important;
  font-family: 'Sunborn-Sansone' !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 24px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mobile-field .MuiInputLabel-root.MuiFormLabel-filled.Mui-focused {
  font-family: 'Sunborn-Sansone' !important;
  padding: 20px 0px 0px 9px !important;
  color: #062C43 !important;
  font-size: 10px !important;
}

.mobile-field-label {
  font-family: 'Sunborn-Sansone' !important;
  padding: 20px 0px 0px 9px !important;
  color: #062C43 !important;
  font-size: 10px !important;
}

/* mobile field custom css end */


.select-custom-css {
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4375em !important;
  letter-spacing: 0.00938em !important;
  padding: 0 !important;
  width: calc(100% - 24px) !important;
  position: absolute !important;
  text-align: center;
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
  color: #E61F00 !important;
}

.css-tattg4-MuiButtonBase-root-MuiButton-root.Mui-disabled {

  color: #474747 !important;
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
  font-family: 'Librefranklin-Regular';
  color: #d32f2f;
}

.css-1wc848c-MuiFormHelperText-root.Mui-error {
  font-family: 'Librefranklin-Regular';
  color: #E61F00 !important;
}

@media screen and (max-width: 468px) {
  h1 {
    font-size: 27px !important;
  }

  button {
    letter-spacing: 2px !important;
  }
}

.sr-only {
  position: absolute;
  left: -1000px;
  top: auto;
  overflow: hidden;
  width: 1px;
  height: 1px;

}

.taxes .text-info-title {
  line-height: 1.0;
  font-family: 'Librefranklin-Regular' !important;
}

.taxes .text-info-desc {
  line-height: 1.0;
  text-align: right;
  font-family: 'Librefranklin-Regular' !important;
}

.product-label {
  position: absolute;
  color: #ffffff;
  top: 20px;
  margin-left: 10px !important;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 7px;
  background: #0075BF;
  /* border: 2px solid #0075BF;
  border-radius: 80px; */
  width: 40%;
  font-size: 12px !important;
  font-family: 'Sunborn-Sansone' !important;
  letter-spacing: 0.7px !important;
  White-Space: nowrap;
  Overflow: hidden;
  Text-overflow: ellipsis;
}

.product-label-Mobile {
  position: absolute;
  color: #ffffff;
  top: 20px;
  margin-left: 10px !important;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 7px;
  background: #0075BF;
  /* border: 2px solid #0075BF;
  border-radius: 80px; */
  width: 75%;
  font-size: 12px !important;
  font-family: 'Sunborn-Sansone' !important;
  letter-spacing: 0.7px !important;
  White-Space: nowrap;
  Overflow: hidden;
  Text-overflow: ellipsis;
}

.product-label-Mobile {
  margin: 10px !important;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  padding: 8px 8px 8px 8px;
  top: 0px;
  width: fit-content;
  background: #0075BF;
  text-align: left;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  padding: 6px;
}

.skip-link:focus {
  position: absolute;
  z-index: 99999;
  top: 0px;
  left: 0px;
  background: #062C43;
  padding: 10px;
  text-decoration: none;
  color: #ffffff;
  font-family: 'Librefranklin-Regular' !important;
}

@media screen and (min-width: 600px) {
  .css-zv4r1b {
    width: 170px !important;
  }
}

@media screen and (min-width: 900px) {
  .css-zv4r1b {
    width: 200px !important;
  }
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  font-family: 'Sunborn-Sansone' !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: 'Librefranklin-Regular' !important;
}

.css-e784if-MuiTypography-root {
  font-family: 'Librefranklin-Regular' !important;
}

.css-vnnh45-MuiTypography-root {
  font-weight: 100 !important;
}

.css-1sn4lm3-MuiTypography-root {
  font-family: 'GritSans-Bold' !important;
}

.css-1m0s4ei-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: 'Librefranklin-Regular' !important;

}

.css-4mkbcn-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: 'Librefranklin-Regular' !important;
}

.css-q2wzpm-MuiTypography-root {
  font-family: 'Librefranklin-Regular' !important;
}

.css-1je76wn-MuiFormLabel-root-MuiInputLabel-root {
  font-family: 'Librefranklin-Regular' !important;
}

.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
  margin: 16px 10px 16px 20px !important;
}

.line-clamp {
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .css-1je76wn-MuiFormLabel-root-MuiInputLabel-root {
    font-family: 'Librefranklin-Regular' !important;
    line-height: 1.2em !important;
  }
}